import React, { Component } from 'react'
import FeaturedBanner from '../../components/FeaturedBanner/FeaturedBanner';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import Toolbar from '../../components/Toolbar/Toolbar';
import './Home.css';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import Backdrop from '../../components/Backdrop/Backdrop';
import HomeBanner from '../../components/HomeBanner/HomeBanner'
import Stats from '../../components/Stats/Stats'
import InvestmentCriteria from '../../components/InvestmentCriteria/InvestmentCriteria'
import { companyName } from '../../constants'


class Home extends Component {
    state = {
        sideDrawerOpen: false,
    }

    // jump to top of page upon mounting
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    drawerToggleClickHandler = () => {
        console.log('Drawer clicked!')
        this.setState((prevState) => {
        return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
    }
    
    backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
    }
    
    drawerLinkClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
    }
    
    render(){
        document.title = companyName;

        let backdrop;
    
        if(this.state.sideDrawerOpen){
        backdrop = <Backdrop click={this.backdropClickHandler} />
        }

        return(

            <div className="home">
                <HomeBanner />  
                
                <div className="welcome-bottom">
                    <p>{companyName} is a private real estate investment firm that owns and manages luxury multifamily assets in major Northeast markets, focused on transit-oriented living and New Jersey’s Gold Coast, the stretch of waterfront running along the Hudson River overlooking breathtaking Manhattan views.</p>
                    <a href="/about" className="learn-more_button">LEARN MORE</a>
                </div>

                <Stats />

                <InvestmentCriteria />

                <div className="featured-banner">
                    <FeaturedBanner />
                </div>

            </div>
        )
    }
}


export default Home