const AWS = require('aws-sdk')

// Initialize the Amazon Cognito credentials provider
AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:3c44b6d6-3aff-4987-85a5-54a94e552cd6',
});

const s3 = new AWS.S3({signatureVersion: 'v4'});
const imageBucket = 'garg-property-images';

var ddb = new AWS.DynamoDB({apiVersion: '2012-08-10'});
const tableName = 'properties'


async function getAllKeys(params,  allKeys = []){
    const response = await s3.listObjectsV2(params).promise();
    response.Contents.forEach(obj => allKeys.push(obj.Key));
  
    if (response.NextContinuationToken) {
      params.ContinuationToken = response.NextContinuationToken;
      await getAllKeys(params, allKeys); // RECURSIVE CALL
    }
    return allKeys;
}


export default {

    getList: async () => {
        const { field, order } = {field: 'displayName', order: "DESC"};
       
        // Prefixes are used to fetch data in parallel.
        const numbers = '0123456789'.split('');
        const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
        const special = "!-_'.*()".split(''); // "Safe" S3 special chars
        const prefixes = [...numbers, ...letters, ...special];

        // array of params used to call listObjectsV2 in parallel for each prefix above
        const arrayOfParams = prefixes.map((prefix) => {
            return { Bucket: imageBucket, Prefix: prefix }
        });

        const allKeys = [];
        await Promise.all(arrayOfParams.map(params => getAllKeys(params, allKeys)));


        let allProperties = []
        allKeys.forEach(key => {

            var s3Params = {
                Bucket: imageBucket, 
                Key: key
            };

            let property = new Promise((resolve,reject) => {
                s3.getObject(s3Params, function(err, data) {
                    if (err) {
                        console.log(err, err.stack);
                        reject(err)
                    } 
                    else {
                        data = JSON.parse(data.Body)
                        //console.log("Get Object data: ", data);
                        resolve( data )
                    }          
                })
            })
            allProperties.push(property)
        })

        return Promise.all(allProperties).then((values) => {
            console.log("Fetched properties: ", values)
            return values.sort(sortByField(field, order)).filter(p => p.displayName !== "Test")
        });
    },


    getOne: async (id) => {
        console.log("Get property with id: ", id)

        var s3Params = {
            Bucket: imageBucket, 
            Key: id
        };

        return await new Promise((resolve,reject) => {
            s3.getObject(s3Params, function(err, data) {
                if (err) {
                    console.log(err, err.stack);
                    reject(err)
                } 
                else {
                    data = JSON.parse(data.Body)
                    console.log("Get Object data: ", data);
                    resolve(data)
                }          
            })
        })
    },

    getLocations: async () => {

        var DDBParams = {
            TableName: tableName,
            ProjectionExpression: "loc",
            FilterExpression: "#R = :r",
            ExpressionAttributeNames: {
                "#R": "realized",
            },
            ExpressionAttributeValues: {
                ":r": {
                    BOOL: false
                }
            }
        };

        return new Promise((resolve, reject) => {
            
            let putItemPromise = ddb.scan(DDBParams).promise();
            putItemPromise.then(function(data) {
                console.log('DynamoDB scan success');
                resolve (data);
            }).catch(function(err) {
                reject(err)
            });
        })


        /* return await new Promise((resolve,reject) => {
            ddb.scan((DDBParams, (err,data) => {
                if (err) {
                    console.log(err, err.stack);
                    reject(err)
                } 
                else {
                    data = JSON.parse(data.Body)
                    console.log("Get location data: ", data);
                    resolve(data)
                }          
            }))
        }) */
    },
};


// sort properties by field
const sortByField = (field, order) => {
    return (a,b) => {
      if(a[field] > b[field]){
        return (order === "DESC" ? 1 : -1);
      }else{
        return (order === "DESC" ? -1 : 1);
      }
    }
  }