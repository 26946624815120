import React, { Component, useEffect, useState } from 'react'
import './Strategy.css'
import ImageBanner from '../../components/ImageBanner/ImageBanner';
import { companyName, colorPrimary } from '../../constants'
import Title from '../../elements/Title/Title'
import { Icon } from 'semantic-ui-react';
import { currentProperties, Properties, caseStudyProperties } from '../../constants'
import { Link } from 'react-router-dom'
import dataProvider from "../../myDataProvider"
import Loader from 'react-loader-spinner'
import { Card, CardHeader, CardMedia, Typography, } from '@material-ui/core';
import { makeStyles,  } from '@material-ui/core/styles';



const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        },
        ["@media (max-width: 700px)"]: {
            marginBottom: '25px',
        },
    },
    header: {
        textAlign: 'center',
       /*  backgroundColor: "black",
        color: 'white', */
    },
    title: {
        textAlign: 'center',
        fontFamily: 'Copperplate',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

    },
    subheader: {
        textAlign: 'center',
        fontFamily: 'Copperplate',
        fontWeight: 300,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
}));


const Strategy = (props) => {
    document.title = "Strategy";

    const classes = useStyles();

    const [isFetching, setIsFetching] = useState(true)
    const [properties, setProperties] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);

        async function fetchData() {
            const propertyData = await dataProvider.getList()

            const caseStudyProperties = propertyData.filter(property => property.featured === true)

            setProperties(caseStudyProperties)
            setIsFetching(false)
        }

        fetchData()
    }, [])


    return(
        <div className="strategy-container">

            <ImageBanner title="Strategy" height="40vh"/>

            <div className="strategy">


                <p className="strategy-summary">
                    The firm’s focused and disciplined strategy, operational and financial value-add expertise and experienced in-house property management team has generated a gross IRR of 29% and equity multiple of 3.45x across all investments in the firm’s portfolio through two real estate cycles 
                </p>
          
                <section id="how-we-do-it" className="strategy-box-section anchor-link">
                    <Title text="How We Do It" />
                    <div className="strategy-boxes">
                        <div className="strategy-box">
                            <h2>Invest</h2>
                            <p>{companyName} primarily acquires undervalued, income producing, stabilized Class A & B assets located in attractive neighborhoods with stable demand and high-income residents, realizing an operational and financial value-enhancing strategy and generating above-average risk-adjusted <b>returns</b>.</p>
                            <p>Our strategy leverages our <b>local reputation</b>, <b>access to off-market</b> and pocket listings, in-depth market research and vertically integrated platform.</p>
                            <p>Our firm has <b>partnered with leading lending institutions</b> that provide non-recourse debt financing at high leverage, excellent financing terms and historically low rates.</p>
                        </div>
                        <div className="strategy-box">
                            <h2>Enhance</h2>
                            <p>{companyName}'s entrepreneurial investment approach employs creative, value-enhancing strategies mainly acquisition value-add, financial value-add and operational value-add while seeking revenue generation and capital structuring opportunities through its aggressive, hands-on management.</p>
                            <a className="value-add-link" href="#value-add-strategy">Value-Add Strategies</a>
                        </div>
                        <div className="strategy-box">
                            <h2>Operate</h2>
                            <p>Our <b>asset management</b> platform maximizes property value and investment returns by reducing operating expenses, seeking revenue generation and mitigating liability and risk, with the assistance of hands-on property management.</p>
                        </div>
                    </div>

                </section>



                <section id="value-add-strategy" className="value-add-section anchor-link">
                    <Title text="Our Value-Add Strategies" />
                        
                        <div className="value-add-item">
                            <div className="value-add-grid">
                                <div className="row">
                                    <div className="row-title">
                                        <h2>Acquisition Value-Add</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3 className="cell">Target Strategy</h3>
                                    <div className="arrow"></div>
                                    <h3 className="cell">Result</h3>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Undervalued secondary markets</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Immediate yield benefits</p><p>Potential for future cap rate compression</p></div>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Transit oriented locations within walking distance to mass transit</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Greater appreciation upside</p><p>Better tenant mix</p></div>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Significantly below replacement cost</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Favorable return dynamics</p></div>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Below market rents</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Easier to achieve 3-5% rent increases</p><p>Additional upside on turnovers and renovations</p></div>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Undermanaged assets</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Immediate value-add opportunities</p></div>
                                </div>
                            </div>
                            <div className="value-add-image">
                                <img src="/backgrounds/jersey_city3.jpg"></img>
                            </div>
                        </div>

                        <div className="value-add-item">
                            <div className="value-add-image">
                                <img src="/backgrounds/jersey_city.jpg"></img>
                            </div>

                             <div className="value-add-grid">
                                <div className="row">
                                    <div className="row-title">
                                        <h2>Financial Value-Add</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3 className="cell">Target Strategy</h3>
                                    <div className="arrow"></div>
                                    <h3 className="cell">Result</h3>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Secure repair credit</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Fund value-added deferred maintenance, capital improvements and renovations</p></div>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Generate ancillary income</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Increased NOI and capitalized value</p></div>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Complete efficient renovations</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>High returns (20% + initial yield)</p></div>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Provide tenants value for money</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Minimize vacancy</p><p>Maintain rent upside</p></div>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Optimize turnover</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Accelerate appreciation</p><p>Enhance cash flow</p></div>
                                </div>
                            </div>
                        </div>

                        <div className="value-add-item">
                            <div className="value-add-grid">
                                <div className="row">
                                    <div className="row-title">
                                        <h2>Operational Value-Add</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3 className="cell">Target Strategy</h3>
                                    <div className="arrow"></div>
                                    <h3 className="cell">Result</h3>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Intensive property and asset management team through our in-house management team</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Maximized NOI, asset value and returns</p></div>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Excellent customer service and resident satisfaction</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Happy communities</p></div>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Thorough resident screening</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Maximize collections and ease operations</p></div>
                                </div>
                                <div className="row">
                                    <div className="cell"><p>Stabilized assets with operational and financial value-add</p></div>
                                    <div className="arrow"><Icon aria-hidden="false" name="angle right" size="big" /></div>
                                    <div className="cell"><p>Generate excess risk-adjusted returns</p></div>
                                </div>
                            </div>

                            <div className="value-add-image">
                                <img src="/backgrounds/jersey_city2.jpg"></img>
                            </div>
                        </div>
                </section>


                <section id="location" className="location-section anchor-link">
                    <Title text="Location"/>

                        <div className="location-flex-box">
                            <div className="location-data">
                                <div className="location-text">
                                <p style={{maxWidth: '1000px'}}>Currently the firm is investing in the Greater New York City area and picked it as a preferred location to invest. There are numerous less competitive, undervalued secondary markets close to more competitive, highly priced markets where we have demonstrated ability to generate superior deal flow from off-market or pocket listings and other special situations.</p>
                                <ul>
                                    <li><p>Stable occupancy, dense populations, high walkability and/or convenient transit access</p></li>
                                    <li><p>big affordability gap between renting and owning due to supply-demand imbalance</p></li>
                                    <li><p>Diverse and stable employment centers with big workforce populations and high rentership rates</p></li>
                                </ul>
                                <br />

                                <h3>NYC/NJ GOLD COAST, PHILADELPHIA, DC, BOSTON</h3>
                                <br />
                                <ul>
                                    <li><p>Affordable housing massively undersupplied</p></li>
                                    <li><p>Dramatic rent increases/chronic housing shortages/low vacancy rates/ownership out of reach</p></li>
                                    <li><p>Urban residents delaying out-migration</p></li>
                                    <li><p>Housing demand driving construction costs and new construction home prices to record highs</p></li>
                                </ul>
                                <br />

                                <h3>TRANSITIONAL NEIGHBORHOODS, IN-FILL LOCATIONS, ACCESSIBLE SUBURBS, SECONDARY CITIES</h3>
                                <br />
                                <ul>
                                    <li><p>$ billions of infrastructures, commercial, housing and mixed-use development</p></li>
                                    <li><p>Significantly discounted rent; 10-75% less than “downtown”</p></li>
                                    <li><p>Increase in in-migration, household formation, population, workforce and wage growth</p></li>
                                    <li><p>Hundreds of thousands of units with transit access, lifestyle benefits and/or better affordability</p></li>
                                    <li><p>Class B/C units with rehab or repositioning potential; new units nearby often rent at 30-50%+ premiums</p></li>
                                    <li><p>Opportunities to buy at cap rates between 5.0% and 7.0% with 30-100%+ discount to replacement cost</p></li>
                                </ul>
                                <div/>
                            </div>
                            </div>
                            <div className="location-image">
                                <img src="backgrounds/jersey_city5.jpg"/>
                            </div>
                        </div>
                </section>


                <section id="case-studies-section" className="case-studies-section anchor-link">
                    <Title text="Case Studies" top="50px" bottom="0px" left="25px" right="25px"/>

                    { isFetching 
                        ? <div className="small-spinner"><Loader type="ThreeDots" color="#4ba9c8" height={80} width={80} /></div> 
                        : <div id='case-studies-grid'>
                            { properties.map((property) => {
                            const caseStudyLink = "/casestudies/" + property.id
                            return (
                                <Link className="featured-item" to={caseStudyLink}>
                                    <Card className={classes.root}>
                                        <CardHeader 
                                            classes={{
                                                header: classes.header,
                                                title: classes.title,
                                                subheader: classes.subheader,
                                            }}
                                            title={property.displayName}
                                            subheader={property.city  + ", " + property.state}
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={property.images[0].src}
                                            title={property.displayName}
                                        />
                                    </Card>
                                </Link>
                            )})}
                        </div>
                    }

                    
                </section>


            </div>

        </div>
    )
}



export default Strategy;