import React, { Component, useEffect, useState } from 'react'
import './TrackRecord.css'
import ImageBanner from '../../components/ImageBanner/ImageBanner';
import { currentProperties, realizedProperties, Properties, colorPrimary } from '../../constants'
import { Link } from 'react-router-dom'
import Title from '../../elements/Title/Title'
import dataProvider from "../../myDataProvider"
import { Card, CardHeader, CardMedia } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner'



const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        },
        ["@media (max-width: 700px)"]: {
            marginBottom: '25px',
        },
    },
    header: {
        textAlign: 'center',
       /*  backgroundColor: "black",
        color: 'white', */
    },
    title: {
        textAlign: 'center',
        fontFamily: 'Copperplate',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

    },
    subheader: {
        textAlign: 'center',
        fontFamily: 'Copperplate',
        fontWeight: 300,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
}));


const TrackRecord = (props) => {
    document.title = "Track Record";

    const classes = useStyles();
    const query = props.match.params.query

    const [activeLink, setActiveLink] = useState(query ? query : "current")

    const investmentQueryClicked = (clickedLink) => {
        setActiveLink(activeLink => clickedLink)
        props.history.push('/trackrecord/' + clickedLink)
    }

    const [isFetching, setIsFetching] = useState(true)
    const [realizedProperties, setRealizedProperties] = useState([])
    const [currentProperties, setCurrentProperties] = useState([])


    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(props.match.params.property)

        async function fetchData() {
            const propertyData = await dataProvider.getList()

            const realizedProperties = propertyData.filter(property => property.realized)
            const currentProperties = propertyData.filter(property => !property.realized)

            console.log(realizedProperties, currentProperties)

            setRealizedProperties(realizedProperties)
            setCurrentProperties(currentProperties)
            setIsFetching(false)
        }

        fetchData()
    }, [])

    return(
        <div className="track-record-container">
            <ImageBanner title="Track Record" height="40vh"/>

            <div className="investment-intro">
                <Title text="Explore Our Success" />
                <p>Our track record of success is the result of three fundamental elements: effective acquisition strategy, diligent investment management and sound operations</p>
                <ul>
                    <li>In the last few years, we have acquired several undervalued stabilized Class A & B assets located in attractive neighborhoods</li>
                    <li>Close to 20 properties acquired and/or recapitalized since 2011 with aggregate purchase price of $75m</li>
                    <li>100% of the realized transactions exceeded underwritten return projections</li>
                </ul>
            </div>

            <section className="investment-section anchor-link" id="investments">
                <div className="investment-query-bar">
                    <h1 style={activeLink === 'current' ? {color: 'white'} : {}} onClick={() => investmentQueryClicked('current')}>Current Investments</h1>
                    <h1 style={activeLink === 'realized' ? {color: 'white'} : {}} onClick={() => investmentQueryClicked('realized')}>Realized Investments</h1>
                </div>


                {isFetching 
                    ? <div className="results-spinner"><Loader type="Circles" color="#4ba9c8" height={80} width={80} /></div> 
                
                    : <div className="investments-grid">
                        { activeLink === 'current' 
                            ? 
                            currentProperties.map((property) => {
                                const caseStudyLink = "/casestudies/" + property.id
                                return (
                                    <Link to={caseStudyLink}>
                                        <Card className={classes.root}>
                                            <CardHeader 
                                                classes={{
                                                    header: classes.header,
                                                    title: classes.title,
                                                    subheader: classes.subheader,
                                                }}
                                                title={property.displayName}
                                                subheader={property.city  + ", " + property.state}
                                            />
                                            <CardMedia
                                                className={classes.media}
                                                image={property.images[0].src}
                                                title={property.displayName}
                                            />
                                        </Card>
                                    </Link>
                                )
                            })
                            : 
                            realizedProperties.map((property) => {
                                const caseStudyLink = "/casestudies/" + property.id
                                return (
                                    <Link to={caseStudyLink}>
                                        <Card className={classes.root}>
                                            <CardHeader 
                                                classes={{
                                                    header: classes.header,
                                                    title: classes.title,
                                                    subheader: classes.subheader,
                                                }}
                                                title={property.displayName}
                                                subheader={property.city  + ", " + property.state}
                                            />
                                            <CardMedia
                                                className={classes.media}
                                                image={property.images[0].src}
                                                title={property.displayName}
                                            />
                                        </Card>
                                    </Link>
                                )
                            })}
                        </div>
                }
            </section>
       </div>
    )
}

export default TrackRecord;