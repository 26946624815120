import React, { Component } from 'react'
import FlexView from 'react-flexview';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import './AboutBanner.css'
import { companyName } from '../../constants';

// backgroundColor: '#F5F5F5',

const AboutBanner = () => {
    return(
        <div className="about-banner">
            <div className="about-items">
            <div className="aboutItem">
                <h3>Who We Are</h3>
                <p className="text">
                    {companyName} is a privately owned, fully integrated real estate investment and operating company focused on multifamily assets in the Greater New York City area.
                </p>
                <p className="text">
                    {companyName} has organically grown its portfolio, beginning with a $5M portfolio at the time of inception in 2011 to $50M+ assets under management. 
                </p>
            </div>
            <div className="aboutItem">
                <h3>How We're Different</h3>
                <p className="text">
                    {companyName}'s vertically integrated investment platform incorporates acquisitions, investment management, leasing and property management of multifamily assets.
                </p>
                <p className="text">
                    {companyName}'s investment strategy focuses on acquiring and managing high-quality income-producing multifamily assets in dynamic markets, targeting core returns over the long term with potential for income growth and asset appreciation. 

                </p>
                <p className="text">
                    Buying income producing assets enables us to hedge against inflation, while also borrowing non-recourse debt at historically low rates.
                </p>
            </div>
            <div className="aboutItem">
                <h3>What We Invest In</h3>
                <p className="text">
                    {companyName} primarily acquires undervalued stabilized Class A & B assets located in attractive neighborhoods with stable demand and high-income residents, realizing an operational and financial value-enhancing <b>strategy</b> and generating above-average risk-adjusted <b>returns</b>.
                </p>
                <Link id="strategy-link" to='/strategy'>Our Strategy</Link>
                
            </div>
            </div>
        </div>
    )
}

//                     <p className="link"><Link to='/portfolio'>Portfolio</Link></p>


export default AboutBanner