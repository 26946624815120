import React, { Component, useEffect } from 'react'
import { Icon } from 'semantic-ui-react';
import { colorPrimary } from '../../constants'

import './Stats.css'


export default props => {

    return (
        <div className="stats-container" style={{background: colorPrimary}}>
            <p className="stats-title">Our Portfolio</p>

            <div className="stats-grid">

                <div className='stats-grid-item'>
                    <div className="stat-icon">
                        <Icon aria-hidden="false" name="warehouse" size="huge" />
                    </div>
                    <div className="stat-text">
                        <p>$75-100M</p>
                        <p>of assets under management</p>
                    </div>
                </div>

                <div className='stats-grid-item'>
                    <div className="stat-icon">
                        <Icon aria-hidden="false" name="building outline" size="huge" />
                    </div>
                        <div className="stat-text">
                        <p>200</p>
                        <p>units in 7 buildings</p>
                    </div>

                </div>

                <div className='stats-grid-item'>
                    <div className="stat-icon">
                        <Icon aria-hidden="false" name="block layout" size="huge" />
                    </div>
                    <div className="stat-text">
                        <p>250,000</p>
                        <p>square feet of multi-family buildings</p>
                    </div>
                </div>

                <div className='stats-grid-item'>
                    <div className="stat-icon">
                        <Icon aria-hidden="false" name="group" size="huge" />
                    </div>
                    <div className="stat-text">
                        <p>96%</p>

                        <p>average occupancy rate</p>
                    </div>
                </div>

                <div className='stats-grid-item'>
                    <div className="stat-icon">
                        <Icon aria-hidden="false" name="money bill alternate outline" size="huge" />
                    </div>
                    <div className="stat-text">
                        <p>$1800</p>
                        <p>average monthly rent</p>
                    </div>
                </div>

                

                <div className='stats-grid-item'>
                    <div className="stat-icon">
                        <Icon aria-hidden="false" name="chart line" size="huge" />
                    </div>
                    <div className="stat-text">
                        <p>15%</p>
                        <p>average annualized asset appreciation since inception</p>                    
                    </div>
                </div>

                <div className='stats-grid-item'>
                    <div className="stat-icon">
                        <Icon aria-hidden="false" name="chart pie" size="huge" />
                    </div>
                    <div className="stat-text">
                        <p>36%</p>
                        <p>IRR</p>                    
                    </div>
                </div>


                <div className='stats-grid-item'>
                    <div className="stat-icon">
                        <Icon aria-hidden="false" name="chart bar" size="huge" />
                    </div>
                    <div className="stat-text">
                        <p>3.45</p>
                        <p>Equity Multiple</p>                    
                    </div>
                </div>

            </div>
            <a id="stats-button" href="/portfolio" style={{background: 'goldenrod', color: 'white', justifyContent: 'center', margin: '0 0 0 0' }}>LEARN MORE</a>

        </div>
    )
}
