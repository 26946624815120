import React from 'react';
import { Link } from 'react-router-dom'
import './SideDrawer.css'



const sideDrawer = props => {
    let drawerClasses = 'side-drawer'
    if(props.show){
        drawerClasses = 'side-drawer open'
    }
    return (
        <nav className={drawerClasses}>
            <Link to="/" onClick={props.drawerLinkClickHandler}><img className="logo" src="/logos/SmallLogo.png" /></Link>
            <ul>
                <li><Link to='/about' onClick={props.drawerLinkClickHandler}>About</Link></li>
                <li><Link to='/portfolio' onClick={props.drawerLinkClickHandler}>Portfolio</Link></li>
                <li><Link to='/strategy' onClick={props.drawerLinkClickHandler}>Strategy</Link></li>
                <li><Link to='/investors' onClick={props.drawerLinkClickHandler}>Investors</Link></li>
                <li><Link to='/trackrecord' onClick={props.drawerLinkClickHandler}>Track Record</Link></li>

               
                <li><Link to='/contact' onClick={props.drawerLinkClickHandler}>Contact</Link></li>
            </ul>
        </nav>
    )
};

export default sideDrawer;