export const companyName = "SKG Capital"
export const colorPrimary = '#4ba9c8'



export const Leaders = {
    sanjay: {
        name: "Sanjay Garg",
        title: "Principal & Founder",
        description: `
            <p>Sanjay is recognized as a result oriented real-estate professional with over ten years of experience in real estate investment, repositioning and asset management of commercial and residential real estate. Seeks to capitalize on event driven and value add real estate investment opportunities for his company and investors to generate above average risk-adjusted returns.</p>
            <p>As Chief Investment Officer/Managing Partner at ` + companyName + `, he primarily formulates investment strategies; sources, underwrites and executes acquisitions; capital structures; monitors performance and provides day-to-day asset management oversight of our portfolio.</p>
            <p>His current acquisition projects include transit-oriented, multi-family and mixed-use developments in the New Jersey’s Gold Coast, the stretch of waterfront running along the Hudson River opposite Manhattan. In last several years, he has acquired several high-quality stabilized assets for his company in the North East with operational and financial value-add opportunities to generate above-average risk-adjusted returns.</p>
            <p>Before starting ` + companyName + `, Sanjay worked on Wall Street for major global investment banks in their trading, risk management and compliance groups.</p>
        `
    },
    sam: {
        name: "Sam (Kusum) K. Garg",
        title: "Chief Financial Officer",
        description: `
            <p>Sam has over thirty years of experience in real estate investments, financing and asset management of residential real estate and hotel asset class. He also brings a wealth of experience in finance and accounting to the company.</p>
            <p>As Chief Financial Officer at ` + companyName + `, he primarily monitors the company’s finances, performance and compliance and provides day-to-day asset management oversight of our portfolio. He also supports acquisitions and capital structures for the company.</p>
            <p>Before joining ` + companyName + `, Sam worked in Ministry of Finance where he headed financing projects for several hotels and other real-estate groups ranging from $10m-$100m.</p>
        `
    }
}



// the properties featured in case studies
export const caseStudyProperties = ['gramercy', 'halsted', 'palisades']

// the properties that are currently under management
export const currentProperties = ['gramercy', 'halsted', 'north_ave', 'palisades', 'william_street'];
// the properties that have been sold
export const realizedProperties = ['grant_ave', 'crawford', 'smith_st', 'bergen_ave'];


const locations = {
    NJ: {
        SOUTH_BOUND_BROOK: {title: 'South Bound Brook', name: 'somerset', state: 'NJ', status: 'active'},
        EAST_ORANGE: {title: 'East Orange', name: 'east_orange', state: 'NJ', status: 'active'},
        ELIZABETH: {title: 'Elizabeth', name: 'elizabeth', state: 'NJ', status: 'active'},
        WEST_NEW_YORK: {title: 'West New York', name: 'west_new_york', state: 'NJ', status: 'active'},
    },
}

export const neighborhoods = [{title: 'South Bound Brook', name: 'somerset', status: 'active'}, {title: 'East Orange', name: 'east_orange', status: 'active'}, {title: 'Elizabeth', name: 'elizabeth', status: 'active'}, {title: 'West New York', name: 'west_new_york', status: 'active'},]


// contains data for all managed properties
export const Properties = {

    grant_ave: {
        fileName: "grant_ave",
        title: "Grant Avenue Apartments",
        cityValue: 'South Bound Brook',
        stateValue: 'NJ',
        description: "",
        images: [0,1,2,3,4,5,6,7].map((i) => '/Properties/gramercy/IMG' + i + '.jpg'),
        thumbnail: '/Properties/thumbnails/grant_ave.jpg',
        status: 'realized',

        caseStudyData: {
            "Property Type": "Multifamily",
            "Acquisition Date": "Jan-13",
            "Year Built": "2010",
            "Size": "18",
            "Percentage Leased": "100.00%",
            "Exit Cap Rate": "5-5.25%",
            "Acquisition Cost": "$1.4m",
            "LTV": "75%",
            "Equity Invested": '$350k',
            "Annualized Rent Growth": "4-5%",
            "Holding Period": "5 Yrs",
            "Realized Gross IRR": "38%",
            "Realized Gross Equity Multiple": "3.8x",
            "Tenant Amenities": "Completely gut renovated in 2010. Separate boilers and hot water heaters for each unit. Ceramic tiles in Kitchen and Bathrooms. Hardwood Flooring in Living room and bedrooms. Separate laundry room with washers and dryers for tenants convienience.",

            investmentHighlights: [
                "Good mix of untis with 1 bedroom & 2 bedrooms.",
                "Transit Oriented, Undervalued MultiFamily Asset.",
                "Well located within few mins from Journal Square main transportation hub in Jersey City and in high demand from residents but needed significant management improvements to increase their value post-acquisition.",
                "Rents below Market by 15-20%. In three years, rents were brought up to Market.",
                "Expenses were lowered by bringing efficiency in managing vacancies, rent increases and turnovers.",
                "NOI was increased by 30% in three years and exit CapRate was compressed. As a result, valuation of the asset was increased by close to 50% by executing the value-add strategy plan."
            ],
        }
    },


    crawford: {
        fileName: "crawford",
        title: "Crawford Street Apartments",
        cityValue: 'Newark',
        stateValue: 'NJ',
        description: "",
        images: [0,1,2,3,4,5,6,7].map((i) => '/Properties/gramercy/IMG' + i + '.jpg'),
        thumbnail: '/Properties/thumbnails/crawford.jpg',
        status: 'realized',

        caseStudyData: {
            "Property Type": "Multifamily",
            "Acquisition Date": "Feb-15",
            "Year Built": "1995",
            "Size": "36",
            "Percentage Leased": "100.00%",
            "Exit Cap Rate": "6.00%",
            "Acquisition Cost": "$2.4m",
            "LTV": "80%",
            "Equity Invested": '$460k',
            "Annualized Rent Growth": "4-5%",
            "Holding Period": "3 Yrs",
            "Realized Gross IRR": "50%",
            "Realized Gross Equity Multiple": "5.0x",
            "Tenant Amenities": "Each unit comes with hardwood floors and tiles in the bedrooms, living room and hallway and updated appliances in kitchen - Gas cooking range. Completely renovated bathroom with ceramic tiles. Paid parking on premises. Laundry facilities on Premises.",

            investmentHighlights: [
                "Good mix of untis with 1 bedroom, 2 bedroom and 3 bedrooms.",
                "Transit Oriented, Undervalued MultiFamily Asset",
                "Well located within few mins from Newark downtown district and in high demand from residents but needed significant management and physical improvements to increase their value post-acquisition.",
                "Rents below Market by 12-15%. During three years, rents were brought up to Market.",
                "Expenses were lowered by bringing efficiency in managing vacancies, rent increases and turnovers.",
                "NOI was increased by 26% in three years and exit CapRate was compressed. As a result, valuation of the asset was increased by close to 60% by executing the value-add strategy plan."
            ],
        }
    },


    smith_st: {
        fileName: "smith_st",
        title: "Smith Street Apartments",
        cityValue: 'Irvington',
        stateValue: 'NJ',
        description: "",
        images: [0,1,2,3,4,5,6,7].map((i) => '/Properties/gramercy/IMG' + i + '.jpg'),
        thumbnail: '/Properties/thumbnails/smith_st.jpg',
        status: 'realized',

        caseStudyData: {
            "Property Type": "Multifamily",
            "Acquisition Date": "Feb-12",
            "Year Built": "2000",
            "Size": "18",
            "Percentage Leased": "100.00%",
            "Exit Cap Rate": "7.50%",
            "Acquisition Cost": "$1m",
            "LTV": "75%",
            "Equity Invested": '$250k',
            "Annualized Rent Growth": "4-5%",
            "Holding Period": "5 Yrs",
            "Realized Gross IRR": "38%",
            "Realized Gross Equity Multiple": "3.6x",
            "Tenant Amenities": "Beautiful well kept Garden Low rise Apartment building with 18 one bedroom units with 13 parking spaces. Centrally located near Springfield Shopping area, two blocks to Public transportation.",	

            investmentHighlights: [
                "Transit Oriented, Undervalued MultiFamily Asset.",
                "Well located within few mins from Irvington main transportation hub in Irvington and in high demand from residents but needed significant management improvements to increase their value post-acquisition.",
                "Rents below Market by 15-20%. In 5 years, rents were brought up to higher than Market Rents.",
                "Expenses were lowered by bringing efficiency in managing vacancies, rent increases and turnovers.",
                "NOI was increased by 30% in 5 years and exit CapRate was compressed. As a result, valuation of the asset was increased by close to 50% by executing the value-add strategy plan.",
            ],
        }
    },


    bergen_ave: {
        fileName: "bergen_ave",
        title: "Bergen Avenue Apartments",
        cityValue: 'Jersey City',
        stateValue: 'NJ',
        description: "",
        images: [0,1,2,3,4,5,6,7].map((i) => '/Properties/gramercy/IMG' + i + '.jpg'),
        thumbnail: '/Properties/thumbnails/bergen_ave.jpg',
        status: 'realized',

        caseStudyData: {
            "Property Type": "Multifamily",
            "Acquisition Date": "Feb-20",
            "Year Built": "2005",
            "Size": "10",
            "Percentage Leased": "100.00%",
            "Exit Cap Rate": "7.00%",
            "Acquisition Cost": "$1.2m",
            "LTV": "75%",
            "Equity Invested": '$300k',
            "Annualized Rent Growth": "5%",
            "Holding Period": "5 Yrs",
            "Realized Gross IRR": "33%",
            "Realized Gross Equity Multiple": "3.2x",
            "Tenant Amenities": "Each unit comes with 3 bedrooms, large Living Room, dining room, Kitchen and a Bath. New hardwood floors in all the rooms and hallway and New appliances in kitchen - Gas cooking range. Completely renovated bathroom with ceramic tiles. Laundry room for tenant convenience.",

            investmentHighlights: [
                "Transit Oriented, Undervalued MultiFamily Asset.",
                "Well located and in high demand from residents but needed significant management and physical improvements to increase their value post-acquisition.",
                "All units are large 3 bedrooms with living room, dining room, kitchen and 2 bathrooms.",
                "Rents below Market by 12-15%.",
                "Expenses could be lowered by bringing efficiency in managing vacancies, rent increases and turnovers.",
                "NOI was increased by 20% in 5 years and exit CapRate was compressed. As a result, valuation of the asset was increased by close to 40% by executing the value-add strategy plan.",
            ],
        }
    },




    gramercy: {
        fileName: "gramercy",
        title: "Gramercy Town Homes",
        cityValue: 'South Bound Brook',
        stateValue: 'NJ',
        description: "<p>Gramercy Townhomes is a luxury townhomes complex featuring 22 luxurious 3-story townhouse-style apartments with unparalleled luxury features, located at 53 Main Street in South Bound Brook, in the heart of the nation's largest metropolitan area - Somerset County. Somerset county contains a balance between urban and suburban neighborhoods and rural countrysides.</p><p>Fine residential communities, beautiful parks, excellent shopping areas, extensive farmlands, numerous historic sites and outstanding business and industry all make Somerset County a desirable place to live, work, and play. Gramercy townhomes is conveniently located .75 miles from the Bound Brook train station on the raritan line of NJ Transit. Each town home residence features Two Bedrooms, 2.5 Baths, a large gourmet Kitchen, an enormous private 2-car Garage plus many fine interior and exterior appointments and architectural details one would only expect in luxury single family homes.</p>",
        images: Array(11).fill().map((_, i) => '/Properties/gramercy/IMG' + i + '.jpg'),
        thumbnail: '/Properties/thumbnails/gramercy.jpg',
        status: 'current',


        caseStudyData: {
            "Property Type": "Multifamily",
            "Acquisition Date": "Jan-18",
            "Year Built": "2017",
            "Size": "22",
            "Percentage Leased": "100.00%",
            "Stabilized Cap Rate": "5-5.25%",
            "Acquisition Cost": "$8m",
            "LTV": "75%",
            "Annualized Rent Growth": "3%",
            "Projected Holding Period": "5 Yrs",
            "Projected Gross IRR": "25%",
            "Projected Gross Equity Multiple": "2.54x",
            "Tenant Amenities": "Gramercy townhomes is conveniently located .75 miles from the Bound Brook train station on the raritan line of NJ Transit. Each town home residence features Two Bedrooms, 2.5 Baths, a large gourmet Kitchen, an enormous private 2-car Garage plus many fine interior and exterior appointments and architectural details one would only expect in luxury single family homes.",

            investmentHighlights: [
                "Undervalued secondary market",
                "Stabalized and unique Class-A asset",
                "Transit oriented, desriable location, in demand townhomes complex",
                "Below Market rents",
                "High income tenant base",
                "Close to major corporations and retail market"
            ],
        }
    },

    halsted: {
        fileName: "halsted",
        title: "Halsted Apartments",
        cityValue: 'East Orange',
        stateValue: 'NJ',
        description: "<p>The Halsted is a new 68-unit luxury apartment community (built in 2016) conveniently located with-in walking distance to the mid-town direct train station, Brick Church (26 minutes to mid-town Manhattan). We offer one and two bedroom units which are all equipped with a washer and dryer, stainless steel appliances, quartz countertops, hardwood floors and are internet and cable ready.</p><p>All tenants have access to the 24-hour fitness center. In addition, we have assigned parking spaces in our fenced parking lot. At The Halsted tenants are responsible for gas but we provide water. Heating, ranges and hot water appliances are powered by natural gas. We have an on site superintendent to make sure that all maintenance issues are handled immediately. Call now to set up a showing. We look forward to hearing from you.</p>",
        images: Array(23).fill().map((_, i) => '/Properties/halsted/IMG' + i + '.jpg'),
        thumbnail: '/Properties/thumbnails/halsted.jpg',
        status: 'current',


        caseStudyData: {
            "Property Type": "Multifamily",
            "Acquisition Date": "Feb-18",
            "Year Built": "2016",
            "Size": "26",
            "Percentage Leased": "100.00%",
            "Stabilized Cap Rate": "5-5.25%",
            "Acquisition Cost": "$6m",
            "LTV": "80%",
            "Annualized Rent Growth": "4%",
            "Projected Holding Period": "5 Yrs",
            "Projected Gross IRR": "30%",
            "Projected Gross Equity Multiple": "3.24x",
            "Tenant Amenities": "24 hour fitness, fenced parking, elevator, lobby and library, walking distance to midtown train station,  all units equipped with washer and dryer, stainless steel applainces, quartz countertops, walnut hardwood flooring.",

            investmentHighlights: [
                "Transit Oriented, Well built Undervalued MultiFamily Asset",
                "Well located and in high demand from residents but needed significant management and physical improvements to increase their value post-acquisition.",
                "Rents below Market by 12-15%",
                "Expenses could be lowered by bringing efficiency in managing vacancies, rent increases and turnovers.",
            ],
        }

    },

    north_ave: {
        fileName: "north_ave",
        title: "North Avenue Apartments",
        cityValue: 'Elizabeth',
        stateValue: 'NJ',
        description: "<p>Experience luxury living in Elizabeth at 1317-1319 North Ave. At this community, you'll have a wide selection of features and amenities like alarm systems, hardwood floors, granite countertops, stainless steel appliances, washers and dryers, 9ft ceiling and much more.</p><p>North Avenue Apartments in Elizabeth is a luxury mix-use apartment complex with 8 Modern, 2-Bedroom/2-Bath luxury condos and 2 retail office spaces. It is Conveniently located close to schools, shopping, banks, restaurants, etc. 2 minute walk to North Elizabeth Train Station, buses to NY steps away, 5 minute drive to Newark Airport.</p>",
        amenities: `<ul>
            <li>Granite Countertops</li>
            <li>Maple Cabinets</li>
            <li>Stainless steel Appliances</li>
            <li>Refrigerator</li>
            <li>Microwave</li>
            <li>Dishwasher</li>
            <li>Washer/Dryer</li>
            <li>High Profile Cooking Range</li>
            <li>Large walk-in closets</li>
            <li>Large energy efficient windows</li>
            <li>9 Foot ceilings</li>
            <li>Recessed lights</li>
            <li>High efficiency HVAC & Tankless water heaters</li>
            <li>Private tenant entrance</li>
            <li>Fully secure- camera system throughout</li>
            <li>Pre-wired for FiOS / Cablevision</li>
        </ul>`,

        images: Array(14).fill().map((_, i) => '/Properties/north_ave/IMG' + i + '.jpg'),
        thumbnail: '/Properties/thumbnails/north_ave.jpg',
        status: 'current',



        caseStudyData: {
            "Property Type": "Multifamily",
            "Acquisition Date": "Feb-18",
            "Year Built": "2017",
            "Size": "12",
            "Percentage Leased": "100.00%",
            "Stabilized Cap Rate": "5.25-5.5%",
            "Acquisition Cost": "$4m",
            "LTV": "80%",
            "Annualized Rent Growth": "4%",
            "Projected Holding Period": "5 Yrs",
            "Projected Gross IRR": "30%",
            "Projected Gross Equity Multiple": "3.02x",
            "Tenant Amenities": "Alarm systems, hardwood floors, granite countertops, stainless steel appliances, washers and dryers, 9ft ceiling and much more.",

            investmentHighlights: [
                "Nice Mixed use Asset in a very desriable location",
                "Excellent Transit oriented asset",
                "High resident demand",
                "Rents below market",
                "Low vacancy",
                "Very well built with luxury amenities"
            ],
        }


    },


    palisades: {
        fileName: "palisades",
        title: "Palisades Apartments",
        cityValue: 'West New York',
        stateValue: 'NJ',
        description: "<p>5005 Palisade Avenue (built in 2010) is a 30-unit, luxury rental building, located in beautiful West New York, NJ near the towns of Weehawken, Hoboken, Guttenberg and North Bergen, NJ. It boasts exceptionally large units, storage on each floor, stainless steel appliances, granite countertops, covered parking, and 10-foot ceilings. Building amenities include an elevator, a large common area lobby, central A/C, gas heat, gas stoves & tankless water heaters, washer/dryer in each unit, and wheelchair access. Garage parking additional $150/spot.</p><p>5005 Palisade Avenue is conveniently located .2 miles (5 minutes walking) east of the Bergen line Avenue Light Rail Station, which brings commuters to Hoboken (14 minutes)and/or to Jersey City (24 minutes). Alternatively, residents of 5005 Palisade Ave can enjoy an easy 25-minute commute to Port Authority in Manhattan via the 156 bus line located minutes walking on 48th and Palisade Ave.<p>",
        images: Array(29).fill().map((_, i) => '/Properties/palisades/IMG' + i + '.jpg'),

        thumbnail: '/Properties/thumbnails/palisades.jpg',
        status: 'current',

        caseStudyData: {
            "Property Type": "Multifamily",
            "Acquisition Date": "Jan-19",
            "Year Built": "2012",
            "Size": "30",
            "Percentage Leased": "100.00%",
            "Stabilized Cap Rate": "5-5.25%",
            "Acquisition Cost": "$10m",
            "LTV": "75%",
            "Annualized Rent Growth": "4%",
            "Projected Holding Period": "5 Yrs",
            "Projected Gross IRR": "24%",
            "Projected Gross Equity Multiple": "2.62x",
            "Tenant Amenities": "Exceptionally large units, storage on each floor, stainless steel appliances, granite countertops, covered parking, and 10-foot ceilings. Building amenities includes a full size elevator, a large common area lobby, central A/C, gas heat, gas stoves & tankless water heaters, washer/dryer in each unit, and wheelchair access.",

            investmentHighlights: [
                "Luxury Transit oriented Asset within walking distance to mass transit",
                "Located in the desriable NJ gold coast",
                "Significantly below replacement cost",
                "Below market rents by 20%",
                "Signaficantly undertmanaged Asset",
                "Need quick and efficient renovations to dictate higher rents"
            ],
        }


    },

    william_street: {
        fileName: "william_street",
        title: "William Street Apartments",
        cityValue: 'East Orange',
        stateValue: 'NJ',
        description: "<p>The William St. Apartments is a new 26-unit apartment community conveniently located with-in walking distance to the mid-town direct train station, Brick Church (26 minutes to mid-town Manhattan).</p><p>We offer studio, one and two bedroom units which are all equipped with full size refrigerators, gas stoves, laminate countertops, hardwood floors, central air and heat and are internet and cable ready. All tenants have access to the 24-hour Laundry room equipped with the latest laundry machines. In addition, we have assigned parking spaces in our fenced parking lot. At the tenants are responsible for gas but we provide water. Heating, ranges and hot water appliances are powered by natural gas.</p><p>We have an on site superintendent to make sure that all maintenance issues are handled immediately.</p>",
        images: Array(12).fill().map((_, i) => '/Properties/william_street/IMG' + i + '.jpg'),
        thumbnail: '/Properties/thumbnails/william_street.jpg',
        status: 'current',



        caseStudyData: {
            "Property Type": "Multifamily",
            "Acquisition Date": "Feb-20",
            "Year Built": "2016",
            "Size": "26",
            "Percentage Leased": "100.00%",
            "Stabilized Cap Rate": "5.25-5.50%",
            "Acquisition Cost": "$5m",
            "LTV": "80%",
            "Annualized Rent Growth": "4%",
            "Projected Holding Period": "5 Yrs",
            "Projected Gross IRR": "27%",
            "Projected Gross Equity Multiple": "2.9x",
            "Tenant Amenities": "All units equipped with full size refrigerators, gas stoves, laminate countertops, hardwood floors, central air and heat and are internet and cable ready. All tenants have access to the 24-hour Laundry room equipped with the latest laundry machines. In addition, we have assigned parking spaces in our fenced parking lot.",

            investmentHighlights: [
                "Transit oriented, well built undervalued multi-family asset",
                "Well located and in high demand from residents but needed significant management and physical improvements to increase their value post-acquisition.",
                "Rents below Market by 12-15%",
                "Expenses could be lowered by bringing efficiency in managing vacancies, rent increases and turnovers.",
            ],
        }

    },
}
