import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Toolbar.css'
import '../SideDrawer/DrawerToggleButton'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import { Switch, useLocation } from 'react-router-dom'
import { currentProperties, Properties, neighborhoods } from '../../constants'
import dataProvider from "../../myDataProvider"


const Toolbar = props => {

        const [locations, setLocations] = useState(null)
        
        // Make the toolbar semi-transparent if we're on the Home screen
        let location = useLocation()
        useEffect(() => {
            let header = document.querySelector("header");
            let classes = header.classList;
            if(location.pathname === '/'){
                classes.remove("toolbar");
                classes.add("transparent");
            }else{
                classes.remove("transparent");
                classes.add("toolbar");
            }

            async function fetchData() {
                const locationData = await dataProvider.getLocations()
                console.log(locationData)
          
                const unique = [...new Set(locationData.Items.map(item => {
                    return (
                        item.loc.M.city.S 
                    );
                }))]                
                setLocations(unique)
            }

            fetchData()
        }, [])


        return(
            <header className="toolbar">
                <nav className="toolbar_nav">
                    <div className="toolbar_toggle-button">
                        <DrawerToggleButton click={props.drawerClickHandler}/>
                    </div>
                    <div className='toolbar_logo'>
                        <Link to='/'><img src="/logos/SKGCapitalLessBoldBlue.png" width="200" height="75" /></Link>
                    </div>
                    <div className='spacer' />
                    <div className='toolbar_nav-items'>
                        <ul className='toolbar_nav-items'>


                            <li className="dropdown">
                                <li><Link to='/about' className="navLink">About</Link></li>
                                <div class="dropdown-content">
                                    <a href="/about#about-banner">Who We Are</a>
                                    <a href="/about#investment-platform">Our Investment Platform</a>
                                    <a href="/about#leadership">Meet Our Leadership</a>
                                </div>
                            </li>


                            <li className="dropdown">
                            <li><Link to='/portfolio' className="navLink">Portfolio</Link></li>
                                <div class="dropdown-content">
                                    {locations && locations.map(location => {
                                        return <a href={'/portfolio/' + "#" + location}>{location}</a>
                                    })}
                                </div>
                            </li>

                            <li className="dropdown">
                                <Link to='/strategy' className="navLink dropbtn">Strategy</Link>
                                <div class="dropdown-content">
                                    <a href="/strategy#how-we-do-it">How We Do It</a>
                                    <a href="/strategy#value-add-strategy">Value-Add Strategy</a>
                                    <a href="/strategy#location">Location</a>
                                    <a href="/strategy#case-studies-section">Case Studies</a>
                                </div>
                            </li>
                                
                            <li className="dropdown">
                            <li><Link to='/investors' className="navLink dropbtn">Investors</Link></li>
                                <div class="dropdown-content">
                                    <a href="/investors#strategies">Equity Investment Strategies</a>
                                    <a href="/investors#gold-coast">Hudson Waterfront/NJ Gold Coast Investment Market</a>
                                    <a href="/investors#west-ny">West New York Rental Market</a>
                                    <a href="/investors#employers">Hudson County Top Employers</a>
                                    <a href="/investors#market-report">Market Report</a>
                                    <a href="/investors#transportation">Hudson County Transportation</a>
                                    {/* <a href="/investors#">West New York</a> */}
                                </div>
                            </li>
                           

                            <li className="dropdown">
                                <Link to='/trackrecord' className="navLink dropbtn">Track Record</Link>
                                <div class="dropdown-content">
                                    <a href="/trackrecord/current#investments">Current Investments</a>
                                    <a href="/trackrecord/realized#investments">Realized Investments</a>
                                </div>
                            </li>
                            
                            <li><Link to='/contact' className="navLink">Contact</Link></li>
                        </ul>
                    </div>
                </nav>
            </header>
        )
}

export default Toolbar
