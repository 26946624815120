import React, { Component, PureComponent, useEffect, useLayoutEffect, useState, useRef } from 'react'
import './InvestorInfoCarousel.css'
import { companyName, colorPrimary } from '../../constants'
import Title from '../../elements/Title/Title'
import { Icon } from 'semantic-ui-react'

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader



const InvestorInfoCarousel = (props) => {

    return(
        <div className="investor-info">
                    {/* <Title text="Our Market" /> */}

                    <div id="gold-coast" className="investor-info-slide anchor-link">
                        <h1 id="pdf-section-header">About the Hudson Waterfront / NJ Gold Coast</h1>
                        <p>
                            The Hudson Waterfront’s high concentration
                            of multi-family properties and proximity
                            to New York City render it one of the most
                            active – and rapidly growing – investment
                            and rental submarkets in the entire
                            New York Metro Statistical Area. Often
                            referred to as New Jersey’s Gold Coast
                            region, this urban area of northeastern
                            New Jersey is situated along the lower
                            reaches of the Hudson River, the Upper New
                            York Bay and the Kill van Kull.
                        </p>
                        <p>
                            Though the term Gold Coast can specifically mean the shoreline, it
                            more often refers to the contiguous 19-mile urban area between the
                            Bayonne Bridge and the George Washington Bridge. Historically, the
                            region had been known as Bergen Neck at the lower peninsula and
                            Bergen Hill at the lower Hudson Palisades.
                        </p>
                        <p>
                            In total, 13 New Jersey municipalities comprise the Hudson
                            Waterfront, which spans Hudson and Bergen counties. To the east
                            lies the New York City boroughs of Manhattan and Brooklyn, to the
                            south is Staten Island, to the west is Newark Bay and the New Jersey
                            Meadowlands and to the north is Palisades Interstate Park.
                        </p>
                        <p>
                            The Hudson Waterfront/Gold Coast has a combined population of
                            approximately 625,000. If incorporated into a single city, it would
                            be the 21st most populous in the country despite having one of the
                            smallest land areas of 35.7 square miles. Due to these conditions, Gold
                            Coast communities have some of the highest residential densities in
                            the United States.
                        </p>
                        <img src="/HudsonCounty.png" id="hudson-county"/>
                        <span>
                            Since the 1990s, the entire Gold Coast has experienced intensive
                            development, consisting primarily of high-density residential
                            buildings, office towers and retail centers. A good portion of this
                            development has focused on redeveloping brownfields that were
                            once factories, warehouses, docks and rail yards, particularly in areas
                            along public transportation corridors. Access to the Hudson-Bergen
                            Light Rail has also been a catalyst for development. The Hudson River
                            Waterfront Walkway also provides contiguous public access to the
                            water’s edge with an 18.5-mile pedestrian promenade. 
                        </span>

                    </div>

                    <div className="investor-info-slide">

                        <img src="/NJCounties.png" id="left-floated"/>
                        
                        <span>
                            The term Gold Coast is a relatively new term coined by real estate agents, brokers and
                            developers in the 1980s, who realized the investment potential of the area. Residential development initiatives soon
                            followed the office tower and mixed-use commercial efforts with an influx of newly
                            constructed single-family homes, multi-family apartments and condominiums. 
                        </span>
                        <div style={{marginTop: "15px"}}/>
                        <span>
                            Today, the Gold Coast commands some of the highest rents in the region. This is
                            attributable to its proximity to Manhattan, where rents are approximately 15% higher and
                            are fueling a mass residential migration across the Hudson River.
                        </span>



                        <div className="points-of-interest">
                            <h4>Nearby Points of Interest</h4>
                            <p>Edgewater Commons (retail, dining & entertainment)</p>
                            <p>Liberty Skyline Park (waterfront walkway)</p>
                            <p>Bergenline Avenue Commercial District (retail & dining)</p>
                            <p>American Dream Meadowlands (retail & entertainment)</p>
                            <p>MetLife Stadium – home of the Giants & Jets</p>
                            <p>Secaucus Factory Outlet Mall (retail, dining & entertainment)</p>
                            <p>Downtown Hoboken (retail, dining & entertainment)</p>
                            <p>Midtown and Lower Manhattan (retail, dining, entertainment, culture)</p>
                            <p>Liberty State Park (waterfront green)</p>
                            <p>New Jersey Performing Arts Center (entertainment & culture)</p>
                        </div>
                    </div>

                    {/* <span id="west-ny">&nbsp;</span> */}

                    <div id="west-ny" className="investor-info-slide anchor-link">
                        <h1 id="pdf-section-header">About West New York, NJ</h1>

                        <img id="west-ny-demographics" src="/WestNYDemographics.png"/>

                        <span>
                            West New York, N.J., is a town in Hudson County and part of the New York metropolitan area. Situated in the heart of the North Hudson
                            geographic region, West New York is bordered
                            by the Hudson River to the east, Union City and Weehawken to the south, Guttenberg to
                            the north, and North Bergen to the west.
                        </span>
                        <div style={{marginTop: "15px"}}/>
                        <span>
                            Nestled atop the New Jersey Palisades, West New York offers picturesque New York City skyline views from the Hudson River waterfront’s 19-mile Gold Coast. 
                            During the past decade, the town has been at the center of the region’s urban renewal initiatives and gentrification that have both yielded premium multi-family investment.
                        </span>
                        <div style={{marginTop: "15px"}}/>
                        <span>
                            With a population of 53,343 people and 11 neighborhoods, West New York is one of the most densely populated municipalities in the United States and the entire world. 
                            Between 2000 and 2010, West New York experienced a significant population surge of 13.9%, which followed a 20% increase between 1990 and 2000.
                        </span>
                        <div style={{marginTop: "15px"}}/>
                        <span>
                            While West New York is best known for its Hudson waterfront transformation and redevelopment, the town’s main thoroughfare of Bergenline Avenue – branded the “Miracle Mile” – is the longest commercial avenue in the state with more than 300 retail stores and restaurants. 
                            The avenue runs from north to south through the municipality. 
                        </span>
                        <div style={{marginTop: "15px"}}/>


                        <img id="west-ny-home-ownership" src="/WestNYHomeOwnership.png"/>

                        <span>
                           In addition to local and regional prominence, West New York has garnered numerous national accolades. 
                           It has been ranked #52 among the Top 100 cities with the highest percentage of renters with a population of 5,000+. West New York also is above the state average in terms of its renter percentage and stands out for the unique mobility of its residents, distinct cultural heritage, local retail strips and revitalized waterfront.
                        </span>
                        <div style={{marginTop: "15px"}}/>
                        <span>
                            According to Neighborhoodscout.com, West New York is among the top 5 percent of U.S. neighborhoods in terms of walkability – which appeals tremendously to tenants’ and multi-family investors’ short list of requirements. 
                            Proximity to mass transit, local shops, schools, dining establishments and parks/recreational/cultural centers are the top- five requisites for both when choosing a place to live and a property in which to invest. 
                        </span>

                        <p>
                            
                        </p>

                    </div>


                    <div id="employers" className="investor-info-slide anchor-link">
                        <h1 id="pdf-section-header">Hudson County Top Employers (500+ Employees)</h1>
                        <div className="employers-lists">
                            <ul>
                                <li>Bank of Tokyo Mitsubishi Trust</li>
                                <li> Barclays Capital Services Inc.</li>
                                <li> Bayonne Hospital</li>
                                <li> Broadridge</li>
                                <li> Brown Bros Harriman & Co</li>
                                <li> Christ Hospital Health Service</li>
                                <li> Citigroup Inc.</li>
                                <li> City of Jersey City</li>
                                <li> Computershare Investor Services LLC</li>
                                <li> CRA Towing & Recovery</li>
                                <li> Daily News</li>
                                <li> Deutsche Bank Trust Co. NJ Ltd</li>
                                <li> Dynamic Delivery Corp.</li>
                                <li> Equitable Life Insurance Co.</li>
                                <li> Fidelity Investments</li>
                                <li> Forest Laboratories Inc.</li>
                                <li> Gardner Asphalt Corp.</li>
                                <li> Hoboken University Medical Center</li>
                            </ul>
                            <ul>
                                <li> Home Depot U.S.A Inc.</li>
                                <li> Hudson Group (Hudson News)</li>
                                <li> ICAP Services North America LLC</li>
                                <li> IDS USA Inc.</li>
                                <li> Information Service Inc.</li>
                                <li> Insurance Service Office Inc.</li>
                                <li> IPC Acquisition Corp.</li>
                                <li> Jerhel Plastics Inc.</li>
                                <li> Jersey City Medical Center</li>
                                <li> John Wiley & Sons Inc.</li>
                                <li> JP Morgan Chase Bank</li>
                                <li> Liberty Health Plan Inc.</li>
                                <li> Lord Abbett & Company</li>
                                <li> Marsh USA Inc.</li>
                                <li> Meadowlands Hospital Medical Center</li>
                                <li> Morgan Stanley Smith Barney</li>
                                <li> National Retail Systems Inc.</li>
                                <li> New Jersey City University</li>
                                <li> New Jersey Transit Corp</li>
                            </ul>
                            <ul>
                                <li> North Hudson Community Action Corp.</li>
                                <li> Palisades General Hospital</li>
                                <li> Pechters Baking Group LLC</li>
                                <li> Pershing LLC/Mellon Bank</li>
                                <li> Prebon Yamane (USA) Inc.</li>
                                <li> Retailers & Manufacturers Distribution</li>
                                <li>Marking Services</li>
                                <li> Ritter Sysco Food Service</li>
                                <li> R.R. Donnelly Communications Inc.</li>
                                <li> Stevens Institute of Technology</li>
                                <li> St. George Trucking and Warehousing</li>
                                <li> The Children’s Place Retail Stores Inc.</li>
                                <li> UBS Financial Services</li>
                                <li> United Services Inc.</li>
                                <li> U.S. Postal Service</li>
                                <li> Vitamin Shoppe Industries Inc.</li>
                            </ul>
                        </div>
                    </div>


                    <div id="market-report" className="investor-info-slide anchor-link">
                        <h1 id="pdf-section-header">The Market Report: Northern New Jersey Submarket: Hudson County</h1>

                        <h3>Submarket Overview</h3>
                        <p>
                            With 61,011 units, amounting to 25.0% of the total metro inventory, the Hudson County submarket is the largest of the seven distinct Northern New Jersey submarkets identified by Reis’ researchers.
                            In the 10-year period beginning with Q2 2010, new additions to
                            the submarket totaled 21,534 units, amounting to an annualized inventory growth rate of 4.4%; over the same period, the metro growth rate has been 1.7%.
                        </p>

                        <h3>Asking and Effective Rent</h3>
                        <p>
                            After registering mixed movement in February and January of 2020, mean unit prices in the submarket are as follows: studio $2,506, one-bedroom $2,990, two-bedroom $3,955 and three-bedroom $5,232 units. Over the past 12 months, asking rents have increased a total of 0.6%, up from $3,343. The Hudson County submarket's April asking rent levels and growth rates compare favorably to
                            the market's averages of $2,073 and 0.3%. Effective rents, which
                            exclude the value of concessions offered to prospective tenants, rose by 0.4% during April to an average of $3,136.
                        </p>

                        <h3>Outlook</h3>
                        <p>
                            Reis’ new construction analysts report that 2,182 units of new speculative apartment inventory will be introduced to the submarket by the end of the year, and net total absorption will be positive 1,258 units. During 2021 and 2022, developers are expected to deliver a total of 5,316 units of market-rate rental apartments to the submarket amounting to 49.8% of the new construction introduced to Northern New Jersey.
                        </p>

                        <div id="market-report-avg-rent">
                            <h3>Average Asking Rent (Per Unit):</h3>
                            <p>$3,347</p>
                        </div>
                    </div>


                    <div id="transportation" className="investor-info-slide anchor-link">
                        <h1 id="pdf-section-header">Hudson County Transportation</h1>
                        <p>A convergence of roadways and rail lines connecting the Northeast pass through Hudson County,
                            rendering it one of the geographic region’s premier transportation crossroads. Statewide, it is the only
                            county where more residents use public transportation than drive. Major terminuses promote the
                            movement of people and goods to high-income executive and professional employment as well as manufacturing and distribution centers in
                            Midtown and Lower Manhattan:
                        </p>

                        <div className="transport-item">
                            <div className="transport-header">
                                <div className="transport-icons"><Icon aria-hidden="false" name="globe" size="large" /></div>
                                <h3>Transit Centers</h3>
                            </div>
                            <div className="transport-text">
                                <p>Journal Square, Exchange Place, Bergenline Avenue at 32nd Street, 48th Street and Nungessers.</p>
                            </div>
                        </div>

                        <div className="transport-item">
                            <div className="transport-header">
                                <div className="transport-icons"><Icon aria-hidden="false" name="train" size="large" /></div>
                                <h3>Rail</h3>
                            </div>
                            <div className="transport-text">
                                <ul>
                                    <li>Hudson-Bergen Light Rail serving Bayonne, Jersey City, Bergenline Avenue and Tonnele Avenue</li>
                                    <li>NJ Transit Main, Bergen County and Pascack Valley lines via Secaucus Junction; Montclair-
                                    Boonton and Morris and Essex lines; North Jersey Coast, Raritan Valley, Meadowlands and
                                    Northeast Corridor lines, and PATH servics</li>
                                </ul>
                            </div>
                        </div>

                        <div className="transport-item">
                            <div className="transport-header">
                                <div className="transport-icons"><Icon aria-hidden="false" name="bus" size="large" /></div>
                                <h3>Bus</h3>
                            </div>
                            <div className="transport-text">
                                <ul>
                                    <li>NJ Transit Routes 120–129 within Hudson and to Manhattan and 1–89 within the county and to North Jersey points</li>
                                    <li>Dollar vans and mini-buses augment state agency-operated surface transportation</li>
                                </ul>
                            </div>
                        </div>

                        <div className="transport-item">
                            <div className="transport-header">
                                <div className="transport-icons"><Icon aria-hidden="false" name="plane" size="large" /></div>
                                <h3>Air</h3>
                            </div>
                            <div className="transport-text">
                                <p>Newark Liberty Airport is less than 30 minutes from each Hudson County municipality and is part of the largest airport system encompassing
                                    JFK and LaGuardia airports in New York. The airport serves 50 carriers and is the third-largest hub for United Airlines, its largest tenant.
                                </p>
                            </div>
                        </div>

                        <div className="transport-item">
                            <div className="transport-header">
                                <div className="transport-icons"><Icon aria-hidden="false" name="ship" size="large" /></div>
                                <h3>Ferry Service</h3>
                            </div>
                            <div className="transport-text">
                                <p>NY Waterway Service to various ports in Manhattan</p>
                            </div>
                        </div>

                        <div className="transport-item">
                            <div className="transport-header">
                                <div className="transport-icons"><Icon aria-hidden="false" name="car" size="large" /></div>
                                <h3>Highways</h3>
                            </div>
                            <div className="transport-text">
                                <p>New Jersey Turnpike Extension; Interstates 78, 95 and 280; Pulaski Skyway, U.S. Route 1/9;
                                    and Routes 3, 7, 139, 185, 440 and 495
                                </p>
                            </div>
                        </div>

                        <div className="transport-item">
                            <div className="transport-header">
                                <div className="transport-icons"><Icon aria-hidden="false" name="road" size="large" /></div>
                                <h3>Tunnels/Bridges</h3>
                            </div>
                            <div className="transport-text">
                                <p>Lincoln Tunnel, Holland Tunnel and Bayonne Bridge</p>
                            </div>
                        </div>
                    </div>
        </div>
    )
}



export default InvestorInfoCarousel;

