import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './CaseStudy.css'
import HorizonalRule from '../../elements/HorizonalRule';
import { Properties } from '../../constants'
import { Icon } from 'semantic-ui-react'
import ImageBanner from '../../components/ImageBanner/ImageBanner'
import ReactHtmlParser from 'react-html-parser';
import { colorPrimary } from '../../constants'
import { MyGoogleMap } from '../../components/MyGoogleMap/MyGoogleMap'
import dataProvider from '../../myDataProvider'
import Loader from 'react-loader-spinner'


const CaseStudy = (props) => {

    const [isFetching, setIsFetching] = useState(true)
    const [property, setProperty] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(props.match.params.property)

        async function fetchData() {
            const propertyData = await dataProvider.getOne(props.match.params.property)
            console.log(propertyData)
            setProperty(propertyData)
            setIsFetching(false)
        }

        fetchData()
    }, [])

    document.title = property ? "Case Study" + property.displayName : "Case Study"
    //document.title = "Case Study" + property.displayName;


    const convertKeyToSentenceCase = (str) => {
        if(str === 'projectedGrossIRR') return 'Projected Gross IRR'
        if(str === 'LTV') return str

        let result = str.replace( /([A-Z])/g, " $1" );
        return result.charAt(0).toUpperCase() + result.slice(1);
    }


    if(isFetching){
        return (<div className="results-spinner" id="case-study-spinner"><Loader type="Circles" color="#4ba9c8" height={80} width={80} /></div>)
    }

    return(
        <div className="case-study-body">
            
                    <div className="case-study-data">
                            <div className="case-study-title">
                                <h1>Case Study</h1>
                                <h2 style={{color: colorPrimary}}>{property.displayName}</h2>
                                <h3>{property.city + ", " + property.state}</h3>
                            </div>


                            <h3 id="data-title">Investment Summary</h3>
                            <div className="case-study-data-wrapper">
                                {Object.keys(property.caseStudy).map((key, i) => {
                                    if(key === 'highlights') return null
                                    if(key === 'amenities'){
                                        return <div className="case-study-data-row"><div className="case-study-data-label"><p>{convertKeyToSentenceCase(key)}:</p></div><div><p>{ReactHtmlParser(property.caseStudy.amenities)}</p></div></div>
                                    }
                                    return (
                                        <div className="case-study-data-row"><div className="case-study-data-label"><p>{convertKeyToSentenceCase(key)}:</p></div><div><p>{property.caseStudy[key]}</p></div></div>
                                    )
                                })}
                            </div>
                        
                            
                            <div className="investment-highlights">
                                <h3 id="data-title">Investment Highlights</h3>
                                <ul>
                                    {ReactHtmlParser(property.caseStudy.highlights)}
                                </ul>
                            </div>
                    </div>
                


                                
                    <div className="case-study-images">
                        <div className="case-study-thumbnail">
                            <img src={property.images[0].src}/>
                        </div>
                    {/*  <div className="case-study-thumbnail">
                            <img src={"/Properties/" + property.fileName + "/map.png"}/>
                        </div> */}
                    </div>
        </div>
    )
}


export default CaseStudy