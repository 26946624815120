import React, { Component, useEffect } from 'react'
import Toolbar from '../../components/Toolbar/Toolbar';
import { Parallax, ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import { Icon } from 'semantic-ui-react';
import ScrollAnimation from 'react-animate-on-scroll';


import HorizonalRule from '../../elements/HorizonalRule'
import AboutBanner from '../../components/AboutBanner/AboutBanner';
import ImageBanner from '../../components/ImageBanner/ImageBanner'
import Title from '../../elements/Title/Title'


import './About.css'

class About extends Component {
    // jump to top of page upon mounting
    componentDidMount() {
        window.scrollTo(0, 0);
    }


   

    render() {
        document.title = "About Us";

        return(
                <div id="main" className="about-main" id="about-banner">

                    <ImageBanner title="About Us" height="40vh" icon={false} />


                    <section className="about-banner-bottom" >
                        <AboutBanner />
                    </section>
                    

                    <section className="about-columns-section anchor-link" id="investment-platform">
                        <Title text="Our Integrated Investment Platform" bottom="10px" />
                        

                        <div className="about-columns">
                            <div className="about-column">
                                <div className="about-column-title">
                                    <h1>Acquisitions</h1>
                                </div>
                                <div><span><Icon aria-hidden="false" name="circle" size="mini" /></span><p>SKG Capital’s acquisitions team identifies well-located and exceptional assets with opportunities to add value enhancing strategies through investment management to improve leasing, market positioning, physical layout and capital structuring.</p></div>
                                <div><span><Icon aria-hidden="false" name="circle" size="mini" /></span><p>Utilizing its broad network of relationships and resources in the commercial real estate, and finance industries, the acquisitions team pursues opportunities unique to its investment expertise. Over 60% of the firm’s acquisitions have been off-market transactions, sourced through the firm’s proprietary network of relationships.</p></div>
                                <div><span><Icon aria-hidden="false" name="circle" size="mini" /></span><p>To date, SKG Capital has deployed approximately $15 million of equity across 8+ assets through two real estate cycles.</p></div>

                            </div>
                            <div className="about-column">
                                <div className="about-column-title">
                                    <h1>Investment Management</h1>
                                </div>
                                <div><span><Icon aria-hidden="false" name="circle" size="mini" /></span><p>The investment management team’s exceptional ability to devise and execute value-enhancing strategies contributes significantly to the firm’s aptitude for successful investments.</p></div>
                                <div><span><Icon aria-hidden="false" name="circle" size="mini" /></span><p>By focusing efforts to create value through revenue generation, adaptive reuse, asset repositioning, redevelopment, and operating expense and capital markets efficiencies, SKG Capital builds equity value while growing cash flow.</p></div>
                                <div><span><Icon aria-hidden="false" name="circle" size="mini" /></span><p>With current annual portfolio revenue of $4.0 million, the investment management platform services a diversity of sophisticated tenants, lenders, investment partners, agents and consultants.</p></div>
                            </div>
                            <div className="about-column">
                                <div className="about-column-title">
                                    <h1>Leasing and Property Management</h1>
                                </div>
                                <div><span><Icon aria-hidden="false" name="circle" size="mini" /></span><p>The firm’s considerable leasing transaction experience along with quality-driven customer service has led to securing good stable tenants with high tenant satisfaction and retention.</p></div>
                                <div><span><Icon aria-hidden="false" name="circle" size="mini" /></span><p>In-house property and construction management capabilities allow for direct investment oversight from hand-on ownership and increased tenant interaction further solidifying the firm’s relationships and standing within the real estate industry.</p></div>
                            </div>
                        </div>
                    </section>


                    <section className="leader-section anchor-link" id="leadership">
                            <Title text="Meet Our Leadership Team" />

                            <div className="leaders">
                                <div className="leader">
                                    <img src="/headshots/sanjay-transparent.png" id="sanjay" />
                                    <h2>Sanjay Garg</h2>
                                    <h3>Principal & Founder</h3>
                                    <a href="/about/sanjay" id="team-member-button">About Sanjay</a>
                                </div>

                                <div className="leader">
                                    <img src="/headshots/sam-transparent.png" id="sam" />
                                    <h2>Sam (Kusum) K. Garg</h2>
                                    <h3>Chief Financial Officer</h3>
                                    <a href="/about/sam" id="team-member-button">About Sam</a>
                                </div>
                            </div>
                    </section>




                </div>
        )
    }
}

export default About


/*

                        <ScrollAnimation offset={150} animateIn="fadeIn">


<section className="team">
                                    <h1>Meet Our Leadership Team</h1>
                                    <HorizonalRule bottomPadding="50px" />
                                    <div className="team-member">
                                        <img src="/headshots/sanjay-transparent.png" />
                                        <div className="team-member-text">
                                            <h2>Sanjay Garg</h2>
                                            <h3>Principal & Founder</h3>

                                            <p>Sanjay is recognized as a result oriented real-estate professional with over ten years of experience in real estate investment, repositioning and asset management of commercial and residential real estate. Seeks to capitalize on event driven and value add real estate investment opportunities for his company and investors to generate above average risk-adjusted returns.</p>
                                            <p>As Chief Investment Officer/Managing Partner at Garg Properties, he primarily formulates investment strategies; sources, underwrites and executes acquisitions; capital structures; monitors performance and provides day-to-day asset management oversight of our portfolio.</p>
                                            <p>His current acquisition projects include transit-oriented, multi-family and mixed-use developments in the New Jersey’s Gold Coast, the stretch of waterfront running along the Hudson River opposite Manhattan. In last several years, he has acquired several high-quality stabilized assets for his company in the North East with operational and financial value-add opportunities to generate above-average risk-adjusted returns.</p>
                                            <p>Before starting Garg Capital, Sanjay worked on Wall Street for major global investment banks in their trading, risk management and compliance groups.</p>
                                        </div>
                                    </div>

                                    <div className="team-member">
                                        <img src="/headshots/sam-transparent.png" />
                                        <div className="team-member-text">
                                            <h2>Sam (Kusum) K. Garg</h2>
                                            <h3>Chief Financial Officer</h3>
                                            <p>Sam has over thirty years of experience in real estate investments, financing and asset management of residential real estate and hotel asset class. He also brings a wealth of experience in finance and accounting to the company.</p>
                                            <p>As Chief Financial Officer at Garg Properties, he primarily monitors the company’s finances, performance and compliance and provides day-to-day asset management oversight of our portfolio. He also supports acquisitions and capital structures for the company.</p>
                                            <p>Before joining Garg Capital, Sam worked in Ministry of Finance where he headed financing projects for several hotels and other real-estate groups ranging from $10m-$100m.</p>
                                        </div>
                                    </div>
                                </section>


*/