import React from 'react'
import { colorPrimary } from '../../constants'
import './Title.css'
import PropTypes from 'prop-types';
import HorizonalRule from "../HorizonalRule"


const Title = (props) => {
    return (
        <div className="title-container" style={{marginTop: props.top, marginBottom: props.bottom, marginLeft: props.horizontal, marginRight: props.horizontal,}}>
            <h1 className="title" style={{color: colorPrimary}}>{props.text}</h1>
        </div>
    )
}


Title.defaultProps = {
    top: '50px',
    bottom: '50px',
    horizontal: '25px',
};



export default Title

// <HorizonalRule style={{marginTop: props.marginVertical, marginBottom: '100px'}}/>
