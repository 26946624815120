import React, { Component } from 'react'
import HorizonalRule from '../../elements/HorizonalRule'
import './InvestmentCriteria.css'
import Title from '../../elements/Title/Title'


const InvestmentCriteria = () => {
    return(
        <div className="investment-criteria-container">


        <Title text="Investment Criteria" />

        <div className="investment-criteria">
            <div className="criteria">
                <img src="/backgrounds/thumbnail1.jpg"/>
                <div className="criteria-overlay">
                    <p className="criteria-text">$5-50+ M Acquisition Value</p>
                </div>
            </div>
            <div className="criteria">
            <img src="/backgrounds/thumbnail2.jpg"/>
                <div className="criteria-overlay">
                    <p className="criteria-text">25-100+ Units</p>
                </div>
            </div>
            <div className="criteria">
            <img src="/backgrounds/thumbnail3.jpg"/>
                <div className="criteria-overlay">
                    <p className="criteria-text">Existing Class A and B Properties</p>
                </div>
            </div>
        </div>

        <a href="/strategy" style={{ margin: 'auto' }} className="learn-more_button">LEARN MORE</a>

        </div>
    )
}

export default InvestmentCriteria