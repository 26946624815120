import React, { Component, useEffect } from 'react'
import './TeamMember.css'
import ImageBanner from '../../components/ImageBanner/ImageBanner';
import { Leaders } from '../../constants'
import ReactHtmlParser from 'react-html-parser';


const TeamMember = (props) => {

    const leader = Leaders[props.match.params.leader]
    const imgSrc = '/headshots/' + props.match.params.leader + '-transparent.png'
    document.title = "Leaders - " + leader.name;


    return(
        <div className="team-member-main">

            <div className="team-member">
                <div className="team-member-text">
                    <h2 style={{}}>{leader.name}</h2>
                    <h3>{leader.title}</h3>
                    {ReactHtmlParser(leader.description)}
                </div>

                <div className="team-member-img-wrapper">
                    <img src={imgSrc} />
                </div>
            </div>
       </div>
    )
}

export default TeamMember;