
import React, { Component, useEffect, useState } from 'react'
import FlexView from 'react-flexview';
import { Link } from 'react-router-dom'
import HorizonalRule from '../../elements/HorizonalRule'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Properties, colorPrimary } from '../../constants'
import { Carousel } from 'react-responsive-carousel';
import ReactHtmlParser from 'react-html-parser';
import Gallery from 'react-grid-gallery';
import './Property.css'
import Title from '../../elements/Title/Title'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Icon } from 'semantic-ui-react';
import dataProvider from "../../myDataProvider"
import Loader from 'react-loader-spinner'






// FULL SCREEN WITH FADING DESCRIPTION UNDERNEATH

const Property = (props) => {

    const [loadedImages, setImages] = useState([])

    const [isFetching, setIsFetching] = useState(true)
    const [property, setProperty] = useState(null)


    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(props.match.params.property)

        async function fetchData() {
            const propertyData = await dataProvider.getOne(props.match.params.property)
            console.log(propertyData)
            setProperty(propertyData)
            setIsFetching(false)
            //document.title = property.displayName;
        }

        fetchData()
    }, [])


    const banner= {
        width: '100vw',
        height: "calc(" + "100vh - 100px" + ")",
        backgroundImage: isFetching ? null : "url(" + property.images[0].src + ")",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        cursor: 'pointer',
    }


    const [isOpen, setIsOpen] = useState(false)
    const [photoIndex, setPhotoIndex] = useState(0)


    if(isFetching) {
        return (
            <div className="property-container">
                <div className="results-spinner" id="property-spinner"><Loader type="Circles" color="#4ba9c8" height={80} width={80} /></div>
            </div>
        )
    }

    return(
        <div className="property-container">
            <div className="property-banner" style={banner} onClick={() => setIsOpen(true)} >
                <div className="property-banner-overlay">
                    <h1>{property.displayName}</h1>
                    <h3>{property.city + ", " + property.state}</h3>

                    <p>Click to view gallery</p>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={property.images[photoIndex].src}
                        nextSrc={property.images[(photoIndex + 1) % property.images.length].src}
                        prevSrc={property.images[(photoIndex + property.images.length - 1) % property.images.length].src}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                        setPhotoIndex(
                            (photoIndex + property.images.length - 1) % property.images.length
                        )
                        }
                        onMoveNextRequest={() =>
                        setPhotoIndex(
                            (photoIndex + 1) % property.images.length
                        )}
                    />
                )}
            </div>
            <div className="property-info-wrapper">
                    <div id="property-description">
                        {ReactHtmlParser(property.description)}
                    </div> 

                    {property.amenities ?
                    <div id="property-amenities">
                        <h3>Apartment Amenities</h3>
                        {ReactHtmlParser(property.amenities)}
                    </div>
                    : null }
            </div>
        </div>
    )
}

export default Property
