import React from 'react'

const HorizonalRule = ({bottomPadding='20px'}) => {
    return (
        <hr
            style={{
                color: 'black',
                backgroundColor: 'black',
                height: '1px',
                width: '100%',
                marginTop: '10px',
            }}
        />
    )
}

export default HorizonalRule