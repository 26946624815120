import React, { Component } from 'react'
import FlexView from 'react-flexview';
import { Link } from 'react-router-dom'
import { Parallax } from 'react-scroll-parallax';
import HorizonalRule from '../../elements/HorizonalRule'
import ScrollAnimation from 'react-animate-on-scroll';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { companyName } from '../../constants'

import './HomeBanner.css'



const HomeBanner = () => {


    return(
        
        <div className="home-banner-container">
            <Carousel style={{objectFit: 'cover'}} infiniteLoop={true} axis='horizontal' swipeable={false} stopOnHover={false} dynamicHeight={true} infiniteLoop={true} autoPlay={true} interval={10000} showThumbs={false} showArrows={false} showStatus={false} showIndicators={false} >
                <div className="slide-container">
                        <img className="carousel-img" src="backgrounds/jersey_city2.jpg" />
                    <div className="slide-text-left">
                    <div className="text-overlay"> 
                        <div>
                            <h1>{companyName}</h1>
                            <p>Real Estate Investment and Management</p>
                        </div> 
                        <div className="tag-line">
                            <p>Structured for Success</p>
                            <p>Vertically integrated to maximize value.</p>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="slide-container">
                    <img className="carousel-img" src="backgrounds/nyc_skyline2.jpg" />
                    <div className="slide-text-right">
                    <div className="text-overlay"> 
                        <div>
                            <h1>{companyName}</h1>
                            <p>Real Estate Investment and Management</p>
                        </div> 
                        <div className="tag-line">
                            <p>A Strategy That Pays</p>
                            <p>Proven low risk, high return investment opportunities.</p>
                        </div>
                    </div>
                        
                    </div>
                </div>
                <div className="slide-container">
                    <img className="carousel-img" src="backgrounds/jersey_city3.jpg" />
                    <div className="slide-text-left">
                    <div className="text-overlay">
                        <div>
                            <h1>{companyName}</h1>
                            <p>Real Estate Investment and Management</p>
                        </div> 
                        <div className="tag-line">
                            <p>Committed Leadership Team</p>
                            <p>Driven by integrity and experience.</p>
                        </div>
                    </div>
                        
                    </div>
                </div>
            </Carousel>
            <div className="welcome-top">
                    <p>{companyName} is a private real estate investment firm that owns and manages luxury multifamily assets in major Northeast markets, focused on transit-oriented living and New Jersey’s Gold Coast, the stretch of waterfront running along the Hudson River overlooking breathtaking Manhattan views.</p>
                    <a href="/about" id="welcome-button" className="learn-more_button">LEARN MORE</a>
            </div>
        </div>

    )
}

{/* <p className="overlay-title">Luxury Real Estate</p>
                <p className="overlay-subtitle ">Acquisition and Management</p>
 */}
export default HomeBanner