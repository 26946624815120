import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Portfolio.css'
import HorizonalRule from '../../elements/HorizonalRule';
import { Properties, currentProperties, neighborhoods, locations } from '../../constants'
import { Icon } from 'semantic-ui-react'
import ImageBanner from '../../components/ImageBanner/ImageBanner'
import ReactHtmlParser from 'react-html-parser';
import dataProvider from "../../myDataProvider"
import Loader from 'react-loader-spinner'



/* {neighborhoods.map(neighborhood => {
    if(neighborhood.status === 'active'){
        return <a href={'/apartments/' + neighborhood.title + "#search-area"}>{neighborhood.title}</a>
    }
})} */

const Portfolio = () => {

    document.title = "Portfolio";


    const [isFetching, setIsFetching] = useState(true)
    const [currentProperties, setCurrentProperties] = useState([])
    const [locations, setLocations] = useState([])
        

    useEffect(() => {
        window.scrollTo(0, 0);

        async function fetchData() {
            const locationData = await dataProvider.getLocations()
            const unique = [...new Set(locationData.Items.map(item => {
                return (
                    item.loc.M.city.S 
                );
            }))]
            console.log("unique:", unique)                
            setLocations(unique) 

            const propertyData = await dataProvider.getList()
            const currentProperties = propertyData.filter(property => !property.realized)
            setCurrentProperties(currentProperties)
            setIsFetching(false)
        }
        fetchData()
    }, [])

    
    if(isFetching || !locations){
        return (
            <div className="portfolio">
                <ImageBanner title="Portfolio" height="40vh"/>
                <div className="results-spinner" id="portfolio-spinner"><Loader type="Circles" color="#4ba9c8" height={80} width={80} /></div>
            </div>
        )
    }

    return(
            <div className="portfolio">

                <ImageBanner title="Portfolio" height="40vh"/>
               
                <div className="properties">
                    {locations !== null && locations.map(loc => 
                        <div className="neighborhood-section anchor-link" id={loc}>
                            <h1 id="neighborhood-title">{loc}</h1>

                            {currentProperties.map(property => {
                                if (!(property.city === loc)) return null

                                return(
                                    <Link to={"/portfolio/" + property.id}>
                                        <div className="property">
                                            <div className="property-img">
                                                <img src={property.images[0].src} alt={property.displayName} />
                                            </div>                       
                                            <div className="property-text">
                                                <h1>{property.displayName}</h1>
                                                <h3>{property.city + ", " + property.state}</h3>
                                                {ReactHtmlParser(property.description)}
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
    
    )
}

/* {currentProperties.map((name) => {
                        
    const property = Properties[name]
    const linkDest = "/portfolio/" + property.fileName
    const imgSource = "/Properties/thumbnails/" + property.fileName + ".jpg"
    
    return (
        <Link to={linkDest}>
            <div className="property">
                <div className="property-img">
                    <img src={imgSource} alt={property.title} />
                </div>                       
                <div className="property-text">
                    <h1>{property.title}</h1>
                    <h3>{property.cityValue + ", " + property.stateValue}</h3>
                    {ReactHtmlParser(property.description)}
                </div>
            </div>
        </Link>
    )
})} */


export default Portfolio