import React, { Component, PureComponent, useEffect, useLayoutEffect, useState, useRef } from 'react'
import './Investors.css'
import ImageBanner from '../../components/ImageBanner/ImageBanner';
import { companyName, colorPrimary } from '../../constants'
import Title from '../../elements/Title/Title'
import { Icon } from 'semantic-ui-react'
import { Carousel } from 'react-responsive-carousel';

import { Document, Page, pdfjs } from 'react-pdf/dist/entry.webpack';
import throttle from "lodash.throttle"
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import InvestorInfoCarousel from '../../components/InvestorInfoCarousel/InvestorInfoCarousel';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const Investors = (props) => {


    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)

    const onDocumentLoadSuccess = ({ numPages }) => {
        console.log(numPages)
        setNumPages(numPages)
    }

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
    
    const previousPage = () => {
        changePage(-1);
    }
    
    const nextPage = () => {
        changePage(1);
    }



    return(

        <div className="investors-container">

            <ImageBanner title="Investors" height="40vh"/>


            <div className="investors-wrapper">


                <div className="investors">
                    <div id="strategies" className="investors-title anchor-link">
                        <Title text={"Investing With " + companyName } />
                        <p className="investors-summary">Our investor clients can access equity investment strategies across the risk/return spectrum, from core and core plus to value-add and opportunistic, in building their real estate portfolios. Investors can participate as Limited partners on a transaction or deal basis to execute their investment objectives.</p>
                    </div>

                    <div className="investors-data">
                        <p><b>Core Strategy</b> - low risk: operating and substantially leased properties in strong markets; diversified across the five major property types: office, retail, industrial, multifamily residential and hotel. Properties have low-to-moderate leverage structures, and income typically generates approximately two-thirds of total return.</p>
                        <p><b>Core-Plus Strategy</b> - moderate risk: core properties in terms of type and locations, with slightly higher elements of risk, including somewhat greater leverage, lease rollovers or modest capital investment requirements. Appreciation is expected to account for approximately 40% of total return.</p>
                        <p><b>Value-Add Strategy</b> - risk levels are somewhat more elevated:​ properties require higher capital investment for re-positioning, re-leasing or enhancement. Leverage is used to enhance returns. Total return over the holding period is generally expected to be divided equally between income and appreciation. For {companyName}'s value-add strategies, please refer to <a href="/strategy#value-add-strategy">our strategies</a>.</p>
                        <p><b>Opportunistic Strategy</b> - higher risk investments with the potential to achieve higher levels of return over the holding period: new development projects and less traditional property sectors.  Assets tend to be significantly leveraged.</p>

                        <InvestorInfoCarousel />

                    </div>
                </div>

                <div className="investors-sidebar">
                    <img src="/Properties/thumbnails/gramercy.jpg"/>
                    <p>Experience has taught us that attractive investment opportunities can be identified at every phase of the real estate cycle.</p>
                </div>
            </div>

        

        </div>
    )
}



export default Investors;









{/* <div className="pdf-section">
                            <AutoSizer disableHeight>
                            {({width}) => (
                                <div id="pdfWrapper" style={{width: "100%"}}>
                                    <Document
                                    className="pdf-document"
                                    file="/GoldCoastMarketOverview.pdf"
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber} width={width}/>
                                    </Document>
                                    <div className="pdf-nagivation-container">
                                        <div className="pdf-nav-controls"> 
                                        <button
                                        className="pdf-button"
                                        type="button"
                                        disabled={pageNumber <= 1}
                                        onClick={previousPage}
                                        >
                                        <Icon name='angle left' color="black" />
                                        </button>
                                        <p>
                                        Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                                        </p>
                                        <button
                                        className="pdf-button"
                                        type="button"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}
                                        >
                                            <Icon name='angle right' color="black" />
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </AutoSizer>
                    </div> */}