import React, { Component } from 'react';
import '../App.css'
import Home from './Home/Home';
import About from './About/About';
import TeamMember from './TeamMember/TeamMember';

import Contact from './Contact/Contact';
import Portfolio from './Portfolio/Portfolio';
import Toolbar from '../components/Toolbar/Toolbar';
import SideDrawer from '../components/SideDrawer/SideDrawer';
import Backdrop from '../components/Backdrop/Backdrop';
import Footer from '../components/Footer/Footer'
import Property from './Property/Property'
import Strategy from './Strategy/Strategy'
import Investors from './Investors/Investors'
import TrackRecord from './TrackRecord/TrackRecord'
import News from './News/News'
import Careers from './Careers/Careers'
import CaseStudy from './CaseStudy/CaseStudy'


import { ParallaxProvider } from 'react-scroll-parallax';
import { BrowserRouter as Router, Switch, Route, Redirect  } from 'react-router-dom';


class App extends Component {

  state = {
    sideDrawerOpen: false,
  }

  
  drawerToggleClickHandler = () => {
    console.log('Drawer clicked!')
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen }
    })
  }

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
  }

  drawerLinkClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
  }

  render(){
    let backdrop;

    if(this.state.sideDrawerOpen){
      backdrop = <Backdrop click={this.backdropClickHandler} />
    }
    return (
      <ParallaxProvider>
      <Router>
          <div className="App">
            <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
            <SideDrawer show={this.state.sideDrawerOpen} drawerLinkClickHandler={this.drawerLinkClickHandler} />
            {backdrop}
            <Switch>
              <Route path="/" exact component={Home}/> 
              <Route path="/about" exact component={About}/>
              <Route path="/about/:leader" component={TeamMember}/>
              <Route path="/portfolio" exact component={Portfolio}/>
              <Route path="/portfolio/:property" component={Property} />
              <Route path="/contact" component={Contact}/>
              <Route path="/strategy" component={Strategy}/>
              <Route path="/investors" component={Investors}/>
              <Route path="/trackrecord" exact component={TrackRecord}/>
              <Route path="/trackrecord/:query" exact component={TrackRecord}/>

              <Route path="/casestudies/:property" component={CaseStudy}/>

              
              <Route render={() => <Redirect to={{pathname: "/"}} />} />
            </Switch>
            <Footer />
          </div>
      </Router>
      </ParallaxProvider>

    );
  }
}

// <Home />
// <Route path="/news" component={News}/>
// <Route path="/careers" component={Careers}/>

export default App;
