import React, { Component, useEffect, useState } from 'react'
import FlexView from 'react-flexview';
import { Link } from 'react-router-dom'
import { Parallax } from 'react-scroll-parallax';
import HorizonalRule from '../../elements/HorizonalRule'
import ScrollAnimation from 'react-animate-on-scroll';
import Title from '../../elements/Title/Title'
import dataProvider from "../../myDataProvider"
import Loader from 'react-loader-spinner'
import { Card, CardHeader, CardMedia } from '@material-ui/core';
import { makeStyles,  } from '@material-ui/core/styles';


import './FeaturedBanner.css'


const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        },
        ["@media (max-width: 700px)"]: {
            marginBottom: '25px',
        },
    },
    header: {
        textAlign: 'center',
       /*  backgroundColor: "black",
        color: 'white', */
    },
    title: {
        textAlign: 'center',
        fontFamily: 'Copperplate',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

    },
    subheader: {
        textAlign: 'center',
        fontFamily: 'Copperplate',
        fontWeight: 300,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
}));


const FeaturedBanner = () => {

    const classes = useStyles();
    const [isFetching, setIsFetching] = useState(true)
    const [properties, setProperties] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);

        async function fetchData() {
            const propertyData = await dataProvider.getList()

            const caseStudyProperties = propertyData.filter(property => property.featured)

            setProperties(caseStudyProperties)
            setIsFetching(false)
        }

        fetchData()
    }, [])

    return(
        
        <div className="featured-container">

        <Title text="Featured Properties" top="50px" bottom="0px" left="25px" right="25px"/>

        { isFetching 
            ? <div className="small-spinner"><Loader type="ThreeDots" color="#4ba9c8" height={80} width={80} /></div> 
            : <div id='case-studies-grid'>
                { properties.map((property) => {
                const propertyLink = "/portfolio/" + property.id
                    return (
                        <Link className="featured-item" to={propertyLink}>
                            <Card className={classes.root}>
                                <CardHeader 
                                    classes={{
                                        header: classes.header,
                                        title: classes.title,
                                        subheader: classes.subheader,
                                    }}
                                    title={property.displayName}
                                    subheader={property.city  + ", " + property.state}
                                />
                                <CardMedia
                                    className={classes.media}
                                    image={property.images[0].src}
                                    title={property.displayName}
                                />
                            </Card>
                        </Link>
                )})}
            </div>
        }
                    
        </div>

    )
}

export default FeaturedBanner